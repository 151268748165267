<template>
    <div class="section" key="marriage">
        <div class="section-title">
            <div class="author"><img width="50" height="50" src="@/assets/images/rabier.png"></div>
            <h3><span>Schritt 6</span> Angaben zur Heirat & Trennung</h3>
            <p>Bitte geben Sie nun an, wann und wo Sie geheiratet haben und seit wann Sie getrennt sind. Auch eine Trennung innerhalb der gemeinsamen Wohnung kann ausreichend sein.</p>
        </div>
        <div class="form-container form-data">
            <div class="input-row">
            <div class="wrap-input">
                <vuejs-datepicker :format="format" :language="de" name="heiratsdatum" v-model="user.datum_heirat" value="date"><span class="label-input" slot="afterDateInput" v-bind:class="{ filled : user.datum_heirat != '' }">Datum der Heirat*</span></vuejs-datepicker>
            </div>
            </div>
            <div class="input-row">
            <div class="wrap-input">
                <vuejs-datepicker :format="format" :language="de" name="trennung" v-model="user.getrennt" value="date"><span class="label-input" slot="afterDateInput" v-bind:class="{ filled : user.getrennt != '' }">Seit wann sind Sie getrennt?*</span></vuejs-datepicker>
            </div>
            </div>
            <div class="input-row">
            <div class="wrap-input">
                <input type="text" name="heiratsort" v-model="user.ort_heirat" @input="incrementChecked()"><span class="label-input" v-bind:class="{ filled : user.ort_heirat.length >= 1 }">Ort der Heirat *</span>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
import vuejsDatepicker from 'vuejs-datepicker';
import {de} from 'vuejs-datepicker/dist/locale'
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'InfoMarriage',
  components: {
    vuejsDatepicker
  },
  data () {
    return {
      de: de,
      format: "d.MM.yyyy"
    }
  },
  computed: mapState({
    user: (state) => state.user,
    checked: (state) => state.checked
  }),
  methods: {
    ...mapMutations([
      'incrementChecked'
    ])
  }
}
</script>